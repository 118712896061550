import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore } from '../../../firebase/firebase';
import Skeleton from '@material-ui/lab/Skeleton';
import { useCustomer } from '../../../providers/CustomerContext';
import { useUser } from '../../../providers/UserContext';
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'
import _ from 'lodash';
import moment from 'moment';

import InvoiceForm from '../../components/Invoice/InvoiceForm';

const InvoiceNew = (props) => {

    //const classes = useStyles();

    const { customers, getCustomers } = useCustomer();
    const { getUsers, users } = useUser();
    const { units, getUnits } = useUnitDescription();

    const [ticketEntryData, setTicketEntryData] = useState([])
    const [internalLoading, setInternalLoading] = useState(false);

    const defaultCurrentInvoice = {
      combinedMaterialCartageSales: false,
      //reportDetail: 'detail',
      invoiceDate: moment().valueOf(),
      //invoiceNumber: '',
      secondaryNumberType: '',
      secndaryNumberValue: '',
      invoiceTerms: 'Net 30 Days',
      attention: '',
      taxOptions: 'calculate',
      gstRate: '5.00',
      rstRate: '0.00',
      useSystemLogo: true,
      cartageOnlyFeeType: 'fuelSurcharge',
      cartageOnlyFee: '0.00',
      cartageAndMaterialFeeType: 'carbonFee',
      cartageAndMaterialFee: '0.00',
      //invoicedToCustomer: false,
    }
    const [currentInvoice, setCurrentInvoice] = useState("currentInvoice" in sessionStorage ? JSON.parse(sessionStorage.getItem("currentInvoice")) : defaultCurrentInvoice)

    const defaultInvoiceFilters = {
      //reportScope: 'all',
      startDate: moment().subtract(1, 'week'),
      endDate: moment(),
      //findByTicketNumber: false,
      ticketFinderOn: false,  //doenst seem to do anything
    }
    const [invoiceFilter, setInvoiceFilter] = useState("invoiceFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("invoiceFilter")) : defaultInvoiceFilters)
    //const [invoiceType, setInvoiceType] = useState("invoiceType" in sessionStorage ? JSON.parse(sessionStorage.getItem("invoiceType")) : 'rate')
    const [selectedRow, setSelectedRow] = useState([]) 
    const [filteredListData, setFilteredListData] = useState([])
    const [ratesData, setRatesData] = useState([])

    //get intital data
    useEffect(() => {
      if(users.length === 0){ getUsers() }
      if(customers.length === 0){ getCustomers() }
      if(units.length === 0){ getUnits() }

      firestore.collection('rates').get()
      .then(querySnapshot => {
          const response = querySnapshot.docs.map((doc) => {
          return {
              ...doc.data(),
              'docId': doc.id,     
          }
          })
          setRatesData(response)
          setInternalLoading(false);  
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    //get data based off invoiceFilter.startDate, invoiceFilter.endDate and invoice 
    useEffect(() => {
      firestore.collection('deliveries')
        .where("invoiceVerified", "==", true)
        .where("date", ">=", moment(invoiceFilter.startDate).format("YYYY-MM-DD"))
        .where("date", "<=", moment(invoiceFilter.endDate).format("YYYY-MM-DD")).get()
        .then(querySnapshot => {
            let ticketentryResponse = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                'docId': doc.id,     
            }
            })
            //filter where invoicePayDate exists
            ticketentryResponse = ticketentryResponse.filter(x=> x.invoicePayDate === null || x.invoicePayDate === undefined )
            setTicketEntryData(ticketentryResponse);
        })
        .catch(e => {
            setInternalLoading(false);
            console.log(e.message);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceFilter.startDate, invoiceFilter.endDate]);

    //filters ticket data for list
    useEffect(() => {
      let container = _.cloneDeep(ticketEntryData)
      
      //field filter - ensure field is present in ticket
      // let orderByField = invoiceFilter.findByTicketNumber ? 'ticket' : invoiceType === 'jobWithSubtotals' ? 'rate' : 'customerId'
      // container = container.filter(x=> x[orderByField] !== undefined && x[orderByField] !== null && x[orderByField] !== '')

      let orderByField = invoiceFilter.findByTicketNumber ? 'ticket' : 'customerId'
      container = container.filter(x=> x[orderByField] !== undefined && x[orderByField] !== null && x[orderByField] !== '')

      // report scope filter
      // container = container.filter(x=> invoiceFilter.reportScope === 'postedOnly' 
      // ? (x.invoicePayDate !== null && x.invoicePayDate !== undefined) : invoiceFilter.reportScope === 'unpostedOnly' 
      // ? (x.invoicePayDate === null || x.invoicePayDate === undefined) : x)

      // invoice type filter
      // if(invoiceType !== 'jobWithSubtotals' && !invoiceFilter.findByTicketNumber){
      //    container = [...container.reduce((map, obj) => map.has(obj.customerId) ? map : map.set(obj.customerId, obj), new Map()).values()]; 
      // }
      // else if(invoiceType === 'jobWithSubtotals' && !invoiceFilter.findByTicketNumber){
      //   container = container.filter(x=> x.rate !== null)
      //   container = [...container.reduce((map, obj) => map.has(obj.rate) ? map : map.set(obj.rate, obj), new Map()).values()]; 
      // }

      if(!invoiceFilter.findByTicketNumber){
         container = [...container.reduce((map, obj) => map.has(obj.customerId) ? map : map.set(obj.customerId, obj), new Map()).values()]; 
      }

      setFilteredListData(container)
    }, [ticketEntryData, invoiceFilter])

    
    const handlePreviewPDF = async () => {
      let ticketDataContainer = []

      //get only the tickets selected if by ticketNumber or jobNumber, else all uninvoiced from that contact
      if(invoiceFilter.findByTicketNumber ){
        ticketDataContainer = selectedRow.map((docId) => {return ticketEntryData.find(x=> x.docId === docId) })
      }
      // else if(invoiceType === 'jobWithSubtotals'){
      //   selectedRow.forEach((row) => {
      //     let currentRate = ticketEntryData.find(x=> x.docId === row)?.rate
      //     let newData = ticketEntryData.filter(x=> x.rate === currentRate)
      //     ticketDataContainer = ticketDataContainer.concat(newData)
      //   })
      // }
      else{
        selectedRow.forEach((row) => {
          let currentCustomer = ticketEntryData.find(x=> x.docId === row)?.customerId
          let newData = ticketEntryData.filter(x=> x.customerId === currentCustomer)
          ticketDataContainer = ticketDataContainer.concat(newData)
        })
      }

      let customersContainer = ticketDataContainer.map((ticket) => { return customers.find(x=> x.docId === ticket.customerId) })

      let unitContainer = [];
      for (const ticket of ticketDataContainer) { unitContainer.push(...await retrieveUnitData(ticket.units)) }


      let ratesList = [...ticketDataContainer.reduce((map, obj) => (map.has(obj.rate) || obj.rate == null)  ? map : map.set(obj.rate, obj.rate), new Map()).values()];
      let ratesContainer = ratesList.map((docId) => {  return ratesData.find(x=> x.docId === docId) })
      
      //get unique list of customers that appear within the tickets, returns the docIds
      //let customerList = [...ticketDataContainer.reduce((map, obj) => map.has(obj.customer) ? map : map.set(obj.customer, obj.customer), new Map()).values()]; 
      //gets each customerJobs based on customer
      //let customerJobContainer = customerList.map((docId) => {  return customerJobs.find(x=> x.customerId === docId) })

      props.history.push({
        pathname: "/dashboard/invoicing/printing/",
        state: { 
          ticketData: JSON.stringify(ticketDataContainer), 
          invoiceDetails: JSON.stringify(currentInvoice), 
          invoiceFilter: JSON.stringify(invoiceFilter),
          //invoiceType: JSON.stringify(invoiceType),
          equipment: JSON.stringify(unitContainer),
          customers: JSON.stringify(customersContainer),
          rates: JSON.stringify(ratesContainer),
          //customerJobs: JSON.stringify(customerJobContainer),
        },
      });
    }

    const retrieveUnitData = async (unitDocIdArray) => {
      let unitsContainer = []

      for (const docId of unitDocIdArray) {
        let findUnit = units.find(x=> x.docId === docId)
        if(!_.isUndefined(findUnit)){ unitsContainer.push(findUnit); }

        else{
          await firestore.collection('units').where("docId", "==", docId).get()
          .then(querySnapshot => {
            const unitResponse = querySnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                'docId': doc.id,
              };
            });
            //get the parent document, if no ref to that document exists add an error field
            // let parentDoc = units.find(x=> x.docId === unitResponse[0].parentUnitDocId)
            // if(_.isUndefined(parentDoc)){ unitsContainer.push({...unitResponse[0]});  }
            // else{ unitsContainer.push(parentDoc);  }

            unitsContainer.push({...unitResponse[0]}); 
          })
        }
      }
      return unitsContainer;
    }

  return (
    <Grid>
        {internalLoading 
        ?
            <Skeleton></Skeleton>
        :      
            <Grid>
                  <InvoiceForm 
                    units={units}
                    users={users}
                    customers={customers}
                    internalLoading={internalLoading}
                    currentInvoice={currentInvoice}
                    setCurrentInvoice={setCurrentInvoice}
                    invoiceFilter={invoiceFilter}
                    setInvoiceFilter={setInvoiceFilter}
                    ticketEntryData={ticketEntryData}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}

                    filteredListData={filteredListData}
                    //invoiceType={invoiceType}
                    //setInvoiceType={setInvoiceType}
                    handlePreviewPDF={handlePreviewPDF}

                    ratesData={ratesData}

                    //filteredJobsArray={filteredJobsArray}
                  />

            </Grid>
        }

    </Grid>
  )
}

export default withRouter(InvoiceNew);