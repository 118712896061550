import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { firestore, functions } from '../../../firebase/firebase'
import { useUser } from '../../../providers/UserContext';
import { useCustomer } from '../../../providers/CustomerContext';
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'
import Skeleton from '@material-ui/lab/Skeleton';
import _ from 'lodash';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';
import moment from 'moment';

import GrainOrdersReport from '../../components/Grain/GrainOrdersReport';

const GrainOrdersHome = (props) => {
    const { getUsers, usersLoading, users } = useUser();
    const { customers, loading, getCustomers } = useCustomer();
    const { units, unitsLoading, getUnits } = useUnitDescription();

    const throwAsyncError = useAsyncError();

    const globalGrainOrdersRef = useRef(null)

    const placesRef = useRef([])
    const perRef = useRef([])
    const materialsRef = useRef([])

    const [grainLoading, setGrainLoading] = useState(true)
    const [pageLoading, setPageLoading] = useState(true)

    const initialgrainOrder = {
        geeTeeOrderNumber: "", 
        orderNumber: "",
        dateCreated: moment().valueOf(),
        ticketDate: moment().valueOf(),
        orderStatus: "active",
        orderPriority: "",

        customer: "",   //docId
        operator: "",   //uuid
        equipment: "",  //docId
        trailer1: "",   //docId
        trailer2: "",   //docId

        origin: "",  
        destination: "", 

        material: "",
        dollarFuelSurcharge: 0.00,
        percentageFuelSurcharge: 0.000,

        //owner
        cartageSales: 0.00,
        cartageSalesUnits: 0,
        ownerPer: "",
        cartageSalesTotal: 0.00,
        linkOperatorToSalesRate: false,  //checkbox bool
        operatorBaseMultiplier: 100.00,

        //operator
        operatorRate: 0,
        adjustedBase: 0.00,
        operatorUnits: 0,
        operatorPer: "",
        payroll: 0.00,

        instructions: "",

        orderType: "",
    }
    const statuses = ['active', 'complete', 'cancelled']
    const priorities = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',]
    const orderTypes = ['deck', 'geetee', 'wtl']
    const pathType = props.history.location.pathname.substring(props.history.location.pathname.lastIndexOf('/') + 1, props.history.location.pathname.length) 

    //get initial data
    useEffect(() => {
        const fetchGrainOrders = async () => {
            // let query = pathType === 'all' ?  : firestore.collection('grainOrders').where('orderType', '==', pathType);

            await firestore.collection('grainOrders').get()
            .then(async querySnapshot => {
                const response = (querySnapshot.docs.map((doc) => {
                    return {
                      ...doc.data(),
                      'docId': doc.id,
                    }
                  }))

                globalGrainOrdersRef.current = response;

                placesRef.current = retrieveField(response, ['origin', 'destination'])
                perRef.current = retrieveField(response, ['ownerPer', 'operatorPer'])
                materialsRef.current = retrieveField(response, ['material'])

                setGrainLoading(false)
            })
            .catch((err) => {
                console.warn(err)
                setGrainLoading(false)
            });
        }

        fetchGrainOrders()
        if(users.length === 0){ getUsers() }
        if(customers.length === 0){ getCustomers() }
        if(units.length === 0){ getUnits() }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //sets page to stop loading after grainOrders have been populated
    useEffect(() => {
        if(!usersLoading && !loading && !unitsLoading && !grainLoading) { setPageLoading(false) }
    }, [grainLoading, usersLoading, loading, unitsLoading])

    const retrieveField = (data, fieldSet) => {
        let returnContainer = []
        fieldSet.forEach(field => {data.forEach((doc) => { (!_.isEmpty(doc[field]) && !_.isUndefined([field])) && returnContainer.push(doc[field]) }) })
        return ([...new Set(returnContainer)])
    }

    const handleSendOperatorInstructionsEmail = async (rows, globalGrainOrdersContainer) => {
        //console.log('handleSendOperatorInstructionsEmail', rows)

        try{
            let emailArray = []

            rows.map((docId) => { return globalGrainOrdersContainer.find(x=> x.docId === docId)}).forEach(async (currentGrainContainer) => {
                let customer = _.find(customers, {docId: currentGrainContainer.customer}) || ''

                let emailObj = {
                    email: _.find(users, {docId: currentGrainContainer.operator})?.email, 
                    subject: currentGrainContainer.geeTeeOrderNumber,
                    orderNumber: currentGrainContainer.orderNumber,
                    ticketDate: moment(currentGrainContainer.ticketDate).format('YYYY-MM-DD'),
                    origin: currentGrainContainer.origin || '', 
                    destination: currentGrainContainer.destination || '',
                    customerName: customer?.name || '', 
                    customerStreetAddress: `${customer?.streetName || ''} ${customer?.streetNumber || ''}`,
                    customerPostalAddress: `${customer?.deliveryAddress?.city || ''}, ${customer?.deliveryAddress?.province || ''} ${customer?.postalCode || ''}`, 
                    equipment: _.find(units, {docId: currentGrainContainer.equipment})?.unitNumber || '',
                    trailer1: _.find(units, {docId: currentGrainContainer.trailer1})?.unitNumber || '',
                    trailer2: _.find(units, {docId: currentGrainContainer.trailer2})?.unitNumber || '',
                    instructions: currentGrainContainer.instructions || '',
                }
                emailArray.push(emailObj)
            })

            const sendEmail = functions.httpsCallable('sendGrainOrderInstructionsEmail');
            await sendEmail({
                sandbox: process.env.NODE_ENV === 'development',
                emailArray: emailArray,
            })
            .then((res) => {
                console.log('res', res)
                //if res empty means error thrown, email not sent
                //res will need to be iterated over for each email
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    const tileClickHandler = (path, searchParams, stateObj) => {
        props.history.push({
          pathname: path,
          state: stateObj,
          search: searchParams && "?" + new URLSearchParams({ docId: searchParams }).toString(),
        })
    };

    const handleSendToTickets = (rows, globalGrain) => {
        return new Promise((resolve, reject) => {
            try{
                let batch = firestore.batch();
                let allGrainOrdersContainer = _.cloneDeep(globalGrain)
    
                for (const container of rows.map((docId) => { return allGrainOrdersContainer.find(x=> x.docId === docId)})) {
                    const ticketContainer = {
                        //ticketId: await getTicketNumber().then((res) => {return res.data}),
                        ticket: container.orderNumber || "Unnumbered",
                        date: moment(container.ticketDate).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
                        customerId: container.customer || null,
                        //job: null,
                        rate: null,
                        //salesRate: null,
                        notes: container.instructions || null, 
                        
                        //switch docId out with uuid ************ when refactor we should replace with uuid ?
                        uid: users.find(x=> x.docId === container.operator)?.uuid || null,
    
                        units: [container.equipment || null, container.trailer1 || null, container.trailer2 || null],
                        //trailer1: container.trailer1 || null,
                        //trailer2: container.trailer2 || null,
                        cartageSaleUnits: container.cartageSalesUnits || null,
                        cartageSaleRate: container.cartageSales || 0.00,
                        cartageSalesPer: container.ownerPer || null,
                        cartageSalesTotal: container.cartageSalesTotal || 0.000,
                        payrollRate: container.operatorRate || 0.00,
                        payrollUnits: container.operatorUnits || null,
                        payrollPer: container.operatorPer || null,
                        payroll: container.payroll || 0.00,
                        payrollAdjustedBase: container.adjustedBase || 1.00,   
                        payrollMultiplier: container.operatorBaseMultiplier || 100.00,
                        linkOperatorToSalesRate: container.linkOperatorToSalesRate || false,
                        material: container.material || null,
                        materialName: null,
                        materialSalesRate: 0.00,
                        quantity: null,
                        materialSalesTotal: 0.00,
                        // materialSales
                        // materialWeight
                        //materialId
                        operatorPayDate: null,
                        invoicePayDate: null,
                        dataEntryDate: moment().valueOf(),
                        orderId: container.geeTeeOrderNumber || "Unnumbered",
                        percentageFuelSurcharge: container.percentageFuelSurcharge || 0.00,
                        dollarFuelSurcharge: container.dollarFuelSurcharge || 0.00,
                        timeIn: null,
                        timeOut: null,
                        nextDay: false,
                        //inputDate: 
                        //timesheetId:
                        orderType: container.orderType || '',

                        from: container.origin || '',
                        to: container.destination || '',
                    }
    
                    let ticketEntriesQuery = firestore.collection('deliveries').doc()
                    batch.set(ticketEntriesQuery, ticketContainer);
    
                    let orderIndexContainer = allGrainOrdersContainer.findIndex(x=> x.docId === container.docId)
                    let orderContainer = allGrainOrdersContainer[orderIndexContainer]
                    orderContainer.orderStatus = 'complete'
                    allGrainOrdersContainer.splice(orderIndexContainer, 1, orderContainer)
    
                    let grainOrderQuery = firestore.collection('grainOrders').doc(container.docId)
                    batch.update(grainOrderQuery, orderContainer);
                }
    
                batch.commit()
                .then(() => {
                    resolve(allGrainOrdersContainer);
                })
            }
            catch(e){
                throwAsyncError(new Error(e.message, e));
                reject(e);
            }
        });
    }

    //get global grain
    const handlePrintOrder = (rows) => {
        props.history.push({
            pathname: "/dashboard/grains/printing/",
            state: { rows: rows.map((docId) => { return globalGrainOrdersRef.current.find(x=> x.docId === docId)}) },
        });
    }

    const checkForAssignedEquipmentOperator = (document) => {
        if((document.operator && document.equipment) || (!document.operator && !document.equipment)){
            return document;
        }
        else{
            let field = document.equipment ? 'equipment' : 'operator';
            let oppositeValue = document.equipment ? 'operator' : 'equipment';

            //try to get realted value
            let newValue = field === 'equipment' 
            ? {operator: users.filter(x=> x.commonEquipment?.fullUnit?.docId === document[field]).pop()?.docId }
            : {equipment: users.find(x=> x.docId === document[field])?.commonEquipment?.fullUnit?.docId } 

            //add new value if it exists
            if(!_.isUndefined(newValue[oppositeValue])){ 
                document[oppositeValue] = newValue[oppositeValue] 
                return document;
            }

            return document;
        }
    }

    console.log('rerender home')

    return (
        <Grid>
            {pageLoading 
            ?
                <Skeleton style={{margin: 'auto', marginTop: '95px'}} variant='rect' width={'90vw'} height={'70vh'} />
            :
                <GrainOrdersReport
                    handleSendOperatorInstructionsEmail={handleSendOperatorInstructionsEmail}
                    tileClickHandler={tileClickHandler}
                    handleSendToTickets={handleSendToTickets}
                    handlePrintOrder={handlePrintOrder}
                    checkForAssignedEquipmentOperator={checkForAssignedEquipmentOperator}

                    users={users}
                    customers={customers}
                    units={units}
                    statuses={statuses}
                    priorities={priorities}
                    placesRef={placesRef}
                    perRef={perRef}
                    materialsRef={materialsRef}
                    orderTypes={orderTypes}
                    pathType={pathType}
                    initialgrainOrder={initialgrainOrder}


                    globalGrainOrdersRef={globalGrainOrdersRef}
                />
            }
        </Grid>
    )
}

export default withRouter(GrainOrdersHome)