import React from 'react';
import { Font, Text, View,StyleSheet,} from '@react-pdf/renderer';
import moment from 'moment';

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    tableText: {
        fontSize: 10,
        marginBottom: '1px',
        textAlign: 'center',
    },
    tableTextBold: {
        fontSize: 10,
        marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginTop: '5px',
        textAlign: 'center',
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
        textAlign: 'center',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    instructionHeader: {
        paddingBottom: '5px',
        fontSize: 12,
        color: 'blue',
    },
});

const PDFContent = (props) => {

    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
    */

    return (
        <View>
            {/* thead */}
            <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: 'row'}}>
                    {props.columns.map((column, columnIndex) => (
                        <Text key={columnIndex} style={{...styles.tableTextBoldUnderline, width: `${column.width}%`}}>
                            {column.header}
                        </Text>
                    ))}
                </View>
            </View>

            {/* tbody */}
            <View>
                {props.ticketData.map((ticket, ticketIndex) => (
                // ticket values
                    <View key={ticketIndex} style={ticketIndex % 2 === 0 ? {flexDirection: 'row', backgroundColor: '#DEDEDE'} : {flexDirection: 'row',}}>
                        {props.columns.map((column, columnIndex) => {
                                switch(column.field) {
                                    case 'date':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {moment(ticket.date).format('DD-MMM-YYYY')} 
                                                </Text>
                                    case 'unitNumber':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {ticket.units.map((unit) => { return ( props.equipment?.find(x=> x?.docId === unit)?.unitNumber ) })} 
                                                </Text>                        
                                    case 'ticket':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {ticket.ticket} 
                                                </Text>
                                    case 'from':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {ticket.from} 
                                                </Text>
                                    case 'to':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {ticket.to} 
                                                </Text>
                                    case 'orderId':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {ticket.orderId} 
                                                </Text>
                                    case 'material':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {ticket.material} 
                                                </Text>
                                    case 'materialUnits':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {Number(ticket.materialUnits || 0).toFixed(3)} 
                                                </Text>                                    
                                    case 'materialSalesRate':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}>  
                                                    {`$${Number(ticket.materialSalesRate || 0).toFixed(2)}`} 
                                                </Text>
                                    case 'materialSalesTotal':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}>  
                                                    {`$${Number(ticket.materialSalesTotal || 0).toFixed(2)}`} 
                                                </Text>                                    
                                    case 'cartageSaleUnitsWPer':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {`${Number(ticket.cartageSaleUnits || 0).toFixed(3)} ${ticket.cartageSalesPer || ''}`} 
                                                </Text>
                                    case 'cartageSaleUnits':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {Number(ticket.cartageSaleUnits || 0).toFixed(3)} 
                                                </Text>
                                    case 'cartageSaleRate':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {`$${Number(ticket.cartageSaleRate || 0).toFixed(2)}`} 
                                                </Text>
                                    case 'cartageSalesTotal':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {`$${Number(ticket.cartageSalesTotal || 0).toFixed(2)}`} 
                                                </Text>
                                    case 'rateTotal':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}> 
                                                    {`$${Number(Number(ticket.cartageSaleRate || 0) + Number(ticket.materialSalesRate || 0)).toFixed(2)}`} 
                                                </Text>  
                                    case 'total':
                                        return <Text style={{...styles.tableText, width: `${column.width}%`}} key={ticketIndex + columnIndex}>  
                                                    {`$${Number(Number(ticket.materialSalesTotal || 0) + Number(ticket.cartageSalesTotal || 0)).toFixed(2)}`} 
                                                </Text>       
                                    default:
                                        console.warn('Field not available within render switch.') 
                                        return null; 
                                }
                        })}
                    </View>
                ))}
                {/* totals */}
                <View style={{flexDirection: 'row'}}>
                    {props.columns.map((column, columnIndex) => {
                        switch(column.field) {
                            case 'date':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />
                            case 'unitNumber':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />       
                            case 'ticket':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />
                            case 'material':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />

                            case 'from':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />
                            case 'to':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />
                            case 'orderId':
                                return <Text style={{...styles.tableText, width: `${column.width}%`}} key={columnIndex} />

                            case 'materialUnits':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}> 
                                            {props.ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.materialUnits || 0)).toFixed(3), 0,)} 
                                        </Text>                                    
                            case 'materialSalesRate':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex} />
                            case 'materialSalesTotal':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}> 
                                            {`$${props.ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.materialSalesTotal || 0)).toFixed(2), 0,)}`} 
                                        </Text>                                    
                            case 'cartageSaleUnitsWPer':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}> 
                                            {props.ticketData.reduce((accumulator, currentValue) => Number(Number(accumulator) + Number(currentValue.cartageSaleUnits || 0)).toFixed(3), 0,)} 
                                        </Text>
                            case 'cartageSaleUnits':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}> 
                                            {props.ticketData.reduce((accumulator, currentValue) => Number(Number(accumulator) + Number(currentValue.cartageSaleUnits || 0)).toFixed(3), 0,)} 
                                        </Text>
                            case 'cartageSaleRate':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex} />
                            case 'cartageSalesTotal':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}>
                                            {`$${props.ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.cartageSalesTotal || 0)).toFixed(2), 0,)}`} 
                                        </Text>
                            case 'rateTotal':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex} />
                            case 'total':
                                return <Text style={{...styles.tableTextBold, width: `${column.width}%`}} key={columnIndex}>
                                            {`$${props.ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + (Number(currentValue.materialSalesTotal || 0) + Number(currentValue.cartageSalesTotal || 0))).toFixed(2), 0,)}`}
                                        </Text>     
                            default:
                                console.warn('Field not available within render switch.')  
                                return null;
                        }
                    })}
                </View>
            </View>
        </View>
    )
};

export default PDFContent;