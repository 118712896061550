import React, {useState, useEffect, useCallback, useRef} from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Snackbar, Button, Tooltip} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { useCustomer } from '../../../providers/CustomerContext';
import { useUser } from '../../../providers/UserContext';
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'
import { firestore } from '../../../firebase/firebase';
import moment from 'moment';
import { functions } from '../../../firebase/firebase';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import GrainOrdersCard from '../../components/Grain/GrainOrdersCard';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1100px',
        margin: 'auto',
    },
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1000px',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const GrainOrders = (props) => {
    const classes = useStyles();
    const throwAsyncError = useAsyncError();

    const { customers, loading, getCustomers } = useCustomer();
    const { getUsers, usersLoading, users } = useUser();
    const { units, unitsLoading, getUnits } = useUnitDescription();

    const [grainOrderLoading, setGrainOrderLoading] = useState(true)
    const [currentGrainOrder, setCurrentGrainOrder] = useState([])

    const [saveState, setSaveState] = useState([])
    const [saving, setSaving] = useState(false)
    const [orderNumberSaveState, setOrderNumberSaveState] = useState(null)

    const [grainOrders, setGrainOrders] = useState([])

    //used to navigate through current filter of grain orders
    const [filteredGrainOrders] = useState(props.history.location.state.grainOrderData)

    const [places, setPlaces] = useState([])
    const [per, setPer] = useState([])
    const [materials, setMaterials] = useState([])

    const [currentIndex, setCurrentIndex] = useState()

    const statuses = useRef(['active', 'complete', 'cancelled'])
    const priorities = useRef(['1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',])
    const orderTypes = useRef(['deck', 'geetee', 'wtl'])

    let path = new URLSearchParams(props.location.search).get("docId");
    
    //get all inital data
    useEffect(() => {
        if(users.length === 0){ getUsers() }
        if(customers.length === 0){ getCustomers() }
        if(units.length === 0){ getUnits() }

        firestore.collection('grainOrders').get()
        .then(async querySnapshot => {
            const response = (querySnapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  'docId': doc.id,
                }
              }))

            let currentOrder = response.find(x=> x.docId === path)
            setPlaces(retrieveField(response, ['origin', 'destination']))
            setPer(retrieveField(response, ['ownerPer', 'operatorPer']))
            setMaterials(retrieveField(response, ['material']))
            setGrainOrders(response)
            setCurrentGrainOrder(currentOrder)

            setSaveState(currentOrder)
            setCurrentIndex(response.map((order) => {return order.docId}).indexOf(currentOrder.docId))
            setOrderNumberSaveState(currentOrder.orderNumber)

            setGrainOrderLoading(false);
        })
        .catch((e) => {
            throwAsyncError(new Error(e.message, e));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 

    //takes in an object that hold field: value as k, v & docId
    const handleSetCurrentGrainOrderCallback = useCallback((changesObj, docId) => {
        try{
            setCurrentGrainOrder((previousValues) => { 
                let container = _.cloneDeep(previousValues)
                for (const [key, value] of Object.entries(changesObj)) { container[key] = value }
                return container
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[], );

    const retrieveField = (data, fieldSet) => {
        let returnContainer = []
        fieldSet.forEach(field => {
            data.forEach((doc) => {  (!_.isEmpty(doc[field]) && !_.isUndefined([field])) && returnContainer.push(doc[field])   }) 
        })
        return ([...new Set(returnContainer)])
    }

    const saveData = async () => {
        setSaving(true)
        firestore.collection('grainOrders').doc(currentGrainOrder.docId)
            .update(currentGrainOrder)
            .then(() => {
                setSaveState(currentGrainOrder)
                setSaving(false)
            }).catch((e) => {
                throwAsyncError(new Error(e.message, e));
                setSaving(false)
            });
    };
    
    const cancelSave = () => {
        setCurrentGrainOrder(saveState)
    }

    //uses filtered grain orders
    const handleChangeCurrentOrder = (indexDifference) => {
        let newIndex = currentIndex + indexDifference
        let newOrder = filteredGrainOrders[newIndex]

        setCurrentIndex(newIndex)
        setSaveState(newOrder)
        setCurrentGrainOrder(newOrder)
    }

    const handlePrintOrder = () => {
        props.history.push({
            pathname: "/dashboard/grains/printing/",
            state: { rows: [currentGrainOrder] },
        });
    }

    const handleSendOperatorInstructionsEmail = async () => {
        try{
            let emailArray = []

            let container = [currentGrainOrder]

            container.forEach(async (currentGrainContainer) => {
                let customer = _.find(customers, {docId: currentGrainContainer.customer}) || ''

                let emailObj = {
                    email: _.find(users, {docId: currentGrainContainer.operator})?.email, 
                    subject: currentGrainContainer.geeTeeOrderNumber,
                    orderNumber: currentGrainContainer.orderNumber,
                    ticketDate: moment(currentGrainContainer.ticketDate).format('YYYY-MM-DD'),
                    origin: currentGrainContainer.origin || '', 
                    destination: currentGrainContainer.destination || '',
                    customerName: customer?.name || '', 
                    customerStreetAddress: `${customer?.streetName || ''} ${customer?.streetNumber || ''}`,
                    customerPostalAddress: `${customer?.deliveryAddress?.city || ''}, ${customer?.deliveryAddress?.province || ''} ${customer?.postalCode || ''}`, 
                    equipment: _.find(units, {docId: currentGrainContainer.equipment})?.unitNumber || '',
                    trailer1: _.find(units, {docId: currentGrainContainer.trailer1})?.unitNumber || '',
                    trailer2: _.find(units, {docId: currentGrainContainer.trailer2})?.unitNumber || '',
                    instructions: currentGrainContainer.instructions || '',
                }
                emailArray.push(emailObj)
            })

            const sendEmail = functions.httpsCallable('sendGrainOrderInstructionsEmail');
            await sendEmail({
                sandbox: process.env.NODE_ENV === 'development',
                emailArray: emailArray,
            })
            .then((res) => {
                console.log('res', res)
                //if res empty means error thrown, email not sent
                //res will need to be iterated over for each email
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
        //needed ?
        //firestore.collection('grainOrders').doc(path).update({'sentOperatorInstructions': true }).catch((e) => {console.log(e);});
    }

    return (
        <Grid className={classes.content}>
            {grainOrderLoading || _.isUndefined(currentGrainOrder)
                ? 
                <Skeleton style={{marginTop: '40px',}} variant='rect' width={'50vw'} height={'50vh'}/>
                : 
                <Grid>
                    <Grid container className={classes.backButton}>
                        <Grid item xs={6}>
                            <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent='flex-end'>
                                <Tooltip title={"Previous Order"}>
                                    <Button
                                        variant='contained'
                                        disabled={(currentIndex - 1) === -1 || (currentIndex - 1) === (filteredGrainOrders.length)}
                                        onClick={() => { handleChangeCurrentOrder(-1) }}>
                                        <NavigateBeforeIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip title={"Next Order"}>
                                    <Button
                                        variant='contained'
                                        disabled={(currentIndex + 1) === -1 || (currentIndex + 1) === (filteredGrainOrders.length)}
                                        onClick={() => { handleChangeCurrentOrder(1) }}>
                                        <NavigateNextIcon />
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                    <GrainOrdersCard
                        globalGrainOrders={grainOrders}
                        currentGrainOrder={currentGrainOrder} 
                        handleSetCurrentGrainOrderCallback={handleSetCurrentGrainOrderCallback}

                        //RANDOM BITS OF NEEDED DATA
                        priorities={priorities.current}
                        statuses={statuses.current}
                        orderTypes={orderTypes.current}
                        users={users}
                        customers={customers}
                        units={units}
                        loading={loading}
                        usersLoading={usersLoading}
                        unitsLoading={unitsLoading}
                        path={path}
                        per={per}
                        places={places}
                        materials={materials}

                        //HIGHER LEVEL FUNCTIONS & ASSOCIATED DATA
                        orderNumberSaveState={orderNumberSaveState}
                        setOrderNumberSaveState={setOrderNumberSaveState}
                        handlePrintOrder={handlePrintOrder}
                        handleSendOperatorInstructionsEmail={handleSendOperatorInstructionsEmail}

                    />

                    <Snackbar
                        open={!_.isEqual(currentGrainOrder, saveState)}
                        message={saving ? 'Saving...' : 'Save Document?'}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        action={
                            <React.Fragment>
                                {saving
                                    ? null
                                    : <React.Fragment>
                                        <Button variant='text' color='primary' onClick={ ()=> { cancelSave() }} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                        <Button variant='contained' onClick={() => { saveData() }}>Confirm</Button>
                                    </React.Fragment>}
                            </React.Fragment>
                        }
                    />
                </Grid>
            }
        </Grid>
    )
}

export default withRouter(GrainOrders)