import React from 'react';
import { withRouter } from 'react-router-dom';
import {Grid, Button, Typography, Paper, Modal, Backdrop, Fade, } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

function CompletionModal(props) {

    return (
        <>
        <Modal
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 0,
            }}
            open={props.open}
            onClose={() => { props.setOpen(false) }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500,}}
        >
            <Fade in={props.open}>
                <Paper style={{ padding: '16px', maxWidth:'700px' }}>
            
                    <Grid container direction='column' spacing={2}>
                        <Grid item>
                            <Typography style={{fontWeight: 'bold'}} align='center'>
                                Are you sure, this will:
                            </Typography>
                            <Typography align='left' style={props.completionProgress >= 2 ? {color: 'green'} : null}>
                                - Generate the invoice number
                            </Typography>
                            <Typography align='left' style={props.completionProgress >= 3 ? {color: 'green'} : null}>
                                - Save the invoice
                            </Typography>
                            <Typography align='left' style={props.completionProgress >= 4 ? {color: 'green'} : null}>
                                - Set each tickets as posted
                            </Typography>
                            <Typography align='left' style={props.completionProgress >= 5 ? {color: 'green'} : null}>
                                {`- Email to these emails: `}  
                                {props.customer?.contacts.filter(x=> x.invoiceContact || x.ccToInvoice).map((contact) => { return contact.email }).join(', ')}
                            </Typography>
                        </Grid>
                        
                        {/* error section */}
                        <Grid container>
                            <Grid container>
                                {props.customer?.contacts.find(x=> x.invoiceContact) === undefined &&
                                    <Typography style={{color: 'red'}}>
                                        <small>
                                            Cannot continue without a customer invoice contact
                                        </small>
                                    </Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container justifyContent='flex-end'>
                            <Grid item>
                                <Button 
                                     onClick={() => { 
                                        props.setOpen(false) 
                                    }} 
                                    style={props.completionProgress !== 0 ? {marginRight: '5px'} : {marginRight: '5px', color: 'white', backgroundColor: 'red'}}
                                    variant="contained"
                                    disabled={props.completionProgress !== 0}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    startIcon={<CheckIcon/>}
                                    variant='contained'
                                    disabled={props.completionProgress !== 0 || props.customer?.contacts.find(x=> x.invoiceContact) === undefined}
                                    onClick={(e) => { 
                                        props.confirmFunction(); 
                                    }}
                                >
                                    {props.completionProgress === 0 ? "Confirm" : props.completionProgress === 4 ? "Success" : "Processing" } 
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid> 
                </Paper>
            </Fade>
        </Modal>

        </>
    )
}

export default withRouter(CompletionModal)